import { FavorableContent } from '@modules/favorites/interfaces/favorable-content.interface';
import { FavoriteDataInterface } from '@modules/favorites/interfaces/favorite-data.interface';
import { LightboxMetadataInterface } from '@modules/shared/interfaces/lightbox.interface';
import { DownloadableContent } from '@modules/shared/services/download.service';
import { SharableContent } from '@modules/shared/services/share.service';

import { MediaDefaultModel } from './media-default.model';
import { MediaType } from './media.types';

export interface MediaTechnicalDataInterface extends SharableContent, DownloadableContent, FavorableContent {
  id: string;
  marsShelfNumber: string | null;
  marsOriginId: string | null;
  title: string;
  caption: string;
  displayDate?: Date;
  type: string;
  mediaType: string;
  pageCount: number;
  formats: MediaTechnicalDataFormatInterface[];
  favoriteData?: FavoriteDataInterface;
  fuelLabel?: string;
}

export interface MediaTechnicalDataFormatInterface {
  id: string;
  name: string;
  filesize: number;
  url: string;
  mimeType: string;
}

export class MediaTechnicalDataModel extends MediaDefaultModel {
  public type = MediaType.TECHNICAL_DATA;
  public mediaType = MediaType.TECHNICAL_DATA;
  public filesize: number;
  public original: MediaTechnicalDataFormatModel;
  public formats: MediaTechnicalDataFormatModel[] = [];
  public pageCount: number;

  constructor(data: MediaTechnicalDataInterface) {
    super(data);
    this.id = data.id;
    this.pageCount = data.pageCount;
    this.formats = data.formats.map((f: any) => new MediaTechnicalDataFormatModel(f));
    this.original =
      (this.formats.find((item) => item.id === 'original') as MediaTechnicalDataFormatModel) || this.formats[0];

    this.filesize = this.original?.filesize || 0;
  }

  public getLightboxMetadata(): LightboxMetadataInterface {
    return {
      ...super.getLightboxMetadata(),
      numberOfPages: this.pageCount,
    };
  }
}

export class MediaTechnicalDataFormatModel {
  public id: string;
  public type = MediaType.TECHNICAL_DATA;
  public name: string;
  public filesize: number;
  public url: string;
  public mimeType: string;

  constructor(data: MediaTechnicalDataFormatInterface) {
    this.id = data.id;
    this.mimeType = data.mimeType;
    this.url = data.url;
    this.name = data.name;
    this.filesize = data.filesize;
  }
}
