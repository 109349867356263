import { Injectable } from '@angular/core';
import { CONSENT_SERVICES, ConsentService } from '@modules/consent/consent.service';

export enum ANALYTIC_EVENTS {
  VIDEO_PLAYED = 'event2',
  USER_LOGGED_IN = 'event3',
  MULTI_ANGLE_VIEW = 'event4',
  SCREEN_CAPTURE = 'event5',
  TRANSCRIPT_BOOKMARKED = 'event6',
  TRANSCRIPT_DOWNLOADED = 'event7',
  TRANSCRIPT_SHARED = 'event8',
  TEXT_SNIPPED_BOOKMARKED = 'event9', // not there anymore
  TEXT_SNIPPED_DOWNLOADED = 'event10', // not there anymore
  TEXT_SNIPPED_SHARED = 'event11', // not there anymore
  ARTICLE_BOOKMARKED = 'event12',
  ARTICLE_DOWNLOADED = 'event13',
  ARTICLE_SHARED = 'event14',
  ASSET_BOOKMARKED = 'event15',
  ASSET_DOWNLOADED = 'event16',
  ASSET_SHARED = 'event17',
  TRANSCRIPT_SHOWN = 'event18',
  TRANSCRIPT_HIDDEN = 'event19',
  QUIZ_STARTED = 'event20', // not there anymore
  QUIZ_FINISHED = 'event21', // not there anymore
  EMBED_VIDEO = 'event22', // just used as listing here, only used in embed player
  AUDIO_PLAYED = 'event26',
  AUDIO_FINISHED = 'event27',
  TRANSCRIPT_LOADED = 'event28',
}

// instance of the catalyst tracking instance created in index.html
declare let s: any;

const BOOKMARK_EVENT_NAMINGS = {
  transcripts: 'event6',
  textSnippet: 'event9', // not there anymore
  articles: 'event12',
  article: 'event12',
  audio: 'event23',
  'editorial-media': 'event15', // not there anymore, sub types should get separate events
  image: 'event15',
  video: 'event15',
  document: 'event15',
};

const DOWNLOAD_EVENT_NAMINGS = {
  transcripts: 'event7',
  textSnippet: 'event10', // not there anymore
  articles: 'event13',
  article: 'event13',
  audio: 'event24',
  'editorial-media': 'event16', // not there anymore, sub types should get separate events
  image: 'event16',
  video: 'event16',
  document: 'event16',
};

const SHARE_EVENT_NAMINGS = {
  transcripts: 'event8',
  textSnippet: 'event11', // not there anymore
  articles: 'event14',
  article: 'event14',
  audio: 'event25',
  'editorial-media': 'event17', // not there anymore, sub types should get separate events
  image: 'event17',
  video: 'event17',
  document: 'event17',
};

const VIEWED_EVENT_NAMINGS = {
  'editorial-media': 'event29', // not there anymore
  document: 'event30',
  image: 'event31',
  video: 'event32',
  audio: 'event33',
};

export enum TRACKING_TYPES {
  BOOKMARK = 'bookmark',
  DOWNLOAD = 'download',
  SHARE = 'share',
  VIEWED = 'viewed',
}

export enum TRACKING_IDENTIFIER {
  ASSET_IDENTIFIER = 'eVar1',
  URL = 'eVar2',
  VIDEO_STATUS = 'eVar3',
  RESOURCE_ID = 'eVar4',
  RESOURCE_LANGUAGE = 'eVar5',
}

/**
 * SiteCatalyst wrapper
 */
@Injectable()
export class TrackingService {
  private s: any;
  private pushEventQueue: { href: string; options: any; title?: string }[] = [];

  // TODO: refactor this so we can listen for consent changes
  //       and either start or stop sending data.
  constructor(private consentService: ConsentService) {
    // s is undefined when tests are running
    if (typeof s !== 'undefined') {
      this.s = s;
    }

    this.consentService.changedConsent.subscribe((data) => {
      if (data[CONSENT_SERVICES.ADOBE] === true) {
        // send out tracked events
        this.pushEventQueue.forEach(({ href, options, title }) => this.push(href, options, title));
        this.pushEventQueue = [];
      }
    });
  }

  // TODO: define interface of a resource
  public trackEvent(resource: any, trackType: TRACKING_TYPES) {
    if (Array.isArray(resource)) {
      resource.forEach((_resource) => this.trackEvent(_resource, trackType));
      return;
    }

    const options: any = {};
    let url;
    // monkey patch data for cards ()
    if (!resource.type) {
      resource.type = resource?.trackingData.type;
    }
    if (!resource.id) {
      resource.id = resource?.trackingData.id;
    }
    // resource type for image is inconsistent
    const type = resource.type === 'structured_image' ? 'image' : resource.type;
    if (type === 'articles' || (type === 'article' && resource.url)) {
      url = `${location.protocol}//${location.host}${resource.url}`;
    } else if (type === 'articles' || type === 'article') {
      // when downloading article, no url is set
      url = `${location.protocol}//${location.host}/article/${resource.id}`;
    } else if (type === 'transcripts') {
      url = resource.id;
    } else {
      url = resource.shareData?.url ?? location.href;
    }
    let eventObj = null;
    switch (trackType) {
      case TRACKING_TYPES.BOOKMARK:
        eventObj = BOOKMARK_EVENT_NAMINGS;
        break;
      case TRACKING_TYPES.DOWNLOAD:
        eventObj = DOWNLOAD_EVENT_NAMINGS;
        break;
      case TRACKING_TYPES.SHARE:
        eventObj = SHARE_EVENT_NAMINGS;
        break;
      case TRACKING_TYPES.VIEWED:
        eventObj = VIEWED_EVENT_NAMINGS;
        break;
    }
    if (!eventObj || !(eventObj as any)[type]) {
      return;
    }
    options.events = (eventObj as any)[type];
    if (type === 'image' || type === 'video' || type === 'document' || type === 'audio' || type === 'technical_data') {
      // add resource identifier for assets (e.g. shelf number or title)
      options[TRACKING_IDENTIFIER.ASSET_IDENTIFIER] = this.getEvar(resource);
    }
    if (resource.id) {
      // Passing resource id
      options[TRACKING_IDENTIFIER.RESOURCE_ID] = resource.id;
    }
    this.push(url, options, document.title);
  }

  public push(href: string, options?: any, title?: string): boolean {
    // s is not yet initialized when we call the constructor
    if (!this.s && typeof s !== 'undefined') {
      this.s = s;
    }

    if (!href) {
      return false;
    }

    if (!this.s || !this.consentService.consentedTo(CONSENT_SERVICES.ADOBE)) {
      this.pushEventQueue.push({ href, options, title });
      return false;
    }

    // ignore pushes which would push the last pushed url
    if (this.s.pageURL === href && !options) {
      return false;
    }
    this.s.pageURL = href;
    this.s[TRACKING_IDENTIFIER.URL] = location.href;
    // currently adobe omniture makes a unique key over pageName, therefore we
    // need to set the page href to pageName
    this.s.pageName = title ? title : document.title;

    if (options) {
      for (const i in options) {
        if (options[i]) {
          s[i] = options[i];
        }
      }
    }

    this.s.t(); // for future reference, this is a page view event which we (ab)use here. For most events s.tl() would be better (track link)

    // reset data
    if (options) {
      for (const i in options) {
        if (options[i]) {
          s[i] = '';
        }
      }
    }
    return true;
  }

  private getEvar(resource: any) {
    if (resource.marsShelfNumber) {
      // && resource.mediaType !== 'video' && resource.mediaType !== 'document') {
      return resource.marsShelfNumber;
    } else if (resource.title) {
      return resource.title;
    } else {
      return document.title;
    }
  }
}
