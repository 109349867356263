import { Injectable } from '@angular/core';
import { ContactPersonModel } from '@models/contact-person.model';
import { ArticleModel } from '@modules/article/models/article.model';
import { MediaAudioInterface, MediaAudioModel } from '@modules/media/models/media-audio.model';
import { MediaDocumentInterface, MediaDocumentModel } from '@modules/media/models/media-document.model';
import { MediaImageInterface, MediaImageModel } from '@modules/media/models/media-image.model';
import { MediaTechnicalDataInterface, MediaTechnicalDataModel } from '@modules/media/models/media-technical-data-model';
import { MediaVideoInterface, MediaVideoModel } from '@modules/media/models/media-video.model';
import { z } from 'zod';

import { ArticleHeaderImageStyle } from '../domain/image-styles/article-header-image.style';
import { PressKitSectionImageStyle } from '../domain/image-styles/press-kit-section.image.style';

import { Adapter } from './adapter';
import { ArticleAdapter } from './article.adapter';
import { ContactPersonAdapter } from './contact.adapter';
import { MediaAdapter } from './media.adapter';

export const PressKitSchema = z
  .object({
    id: z.string().uuid(),
    mars_origin_id: z.string(),
    title: z.string(),
    type: z.string(),
    content: z
      .object({
        value: z.string(),
      })
      .nullable(),
    display_date: z.string().nullable(),
    fuel_label_text: z
      .object({
        processed: z.string(),
      })
      .nullable(),
    location: z.string().nullable(),
    main_image: z.any().nullable(),
    articles: z.any().array().nullable(),
    audios: z.any().array().nullable(),
    contact_persons: z.any().array().nullable(),
    documents: z.any().array().nullable(),
    technical_data: z.any().array().nullable(),
    images: z.any().array().nullable(),
    videos: z.any().array().nullable(),
    image_count: z
      .number()
      .nullable()
      .transform((value) => value || 0),
    video_count: z
      .number()
      .nullable()
      .transform((value) => value || 0),
    audio_count: z
      .number()
      .nullable()
      .transform((value) => value || 0),
    document_count: z
      .number()
      .nullable()
      .transform((value) => value || 0),
    technical_data_count: z
      .number()
      .nullable()
      .transform((value) => value || 0),
    article_count: z
      .number()
      .nullable()
      .transform((value) => value || 0),
    topic_tree: z
      .object({
        drupal_internal__tid: z.number(),
      })
      .nullable(),
  })
  .transform((pressKit) => {
    return {
      id: pressKit.id,
      marsOriginId: pressKit.mars_origin_id,
      topicId: pressKit.topic_tree?.drupal_internal__tid?.toString(),
      title: pressKit.title,
      type: pressKit.type,
      content: pressKit.content?.value || '',
      displayDate: pressKit.display_date || '',
      fuelLabel: pressKit.fuel_label_text?.processed || '',
      location: pressKit.location,
      mainImage: pressKit.main_image,
      articles: pressKit.articles?.map((article) => article) || [],
      audios: pressKit.audios?.map((audio) => audio) || [],
      contactPersons: pressKit.contact_persons?.map((contactPerson) => contactPerson) || [],
      documents: pressKit.documents?.map((document) => document) || [],
      technicalDatas: pressKit.technical_data?.map((document) => document) || [],
      images: pressKit.images?.map((image) => image) || [],
      videos: pressKit.videos?.map((video) => video) || [],
      imageCount: pressKit.image_count,
      videoCount: pressKit.video_count,
      audioCount: pressKit.audio_count,
      documentCount: pressKit.document_count,
      technicalDataCount: pressKit.technical_data_count,
      articleCount: pressKit.article_count,
      downloadData: {
        id: pressKit.id,
        type: 'presskit',
        fileType: 'presskit',
        format: 'ZIP',
        title: pressKit.title,
        assets: {
          imageCount: pressKit.image_count,
          videoCount: pressKit.video_count,
          audioCount: pressKit.audio_count,
          documentCount: pressKit.document_count,
          technicalDataCount: pressKit.technical_data_count,
        },
      },
      shareData: {
        title: pressKit.title,
        content: pressKit.content?.value ?? '',
        url: location.origin + `/press-kit/${pressKit.id}`,
      },
      favoriteData: {
        id: pressKit.id,
        type: 'presskit',
      },
      trackingData: {
        id: pressKit.id,
        type: 'press_kit' as 'press_kit',
        title: pressKit.title,
        marsOriginId: pressKit.mars_origin_id,
        shelfNumber: undefined,
      },
      metaItems: [
        {
          label: pressKit.article_count === 1 ? 'ARTICLE_N' : 'ARTICLES_N',
          count: pressKit.article_count,
          data: 'article_article_section',
        },
        {
          label: pressKit.image_count === 1 ? 'IMAGE_N' : 'IMAGES_N',
          count: pressKit.image_count,
          data: 'article_image_section',
        },
        {
          label: pressKit.video_count === 1 ? 'VIDEO_N' : 'VIDEOS_N',
          count: pressKit.video_count,
          data: 'article_video_section',
        },
        {
          label: pressKit.audio_count === 1 ? 'AUDIO_N' : 'AUDIOS_N',
          count: pressKit.audio_count,
          data: 'article_audio_section',
        },
        {
          label: pressKit.document_count === 1 ? 'DOCUMENT_N' : 'DOCUMENTS_N',
          count: pressKit.document_count,
          data: 'article_document_section',
        },
        {
          label: pressKit.technical_data_count === 1 ? 'TECHNICAL_DATA_N' : 'TECHNICAL_DATAS_N',
          count: pressKit.technical_data_count,
          data: 'article_technical_data_section',
        },
      ],
    };
  });

export const PressKitSectionSchema = z
  .object({
    id: z.string().uuid(),
    type: z.string(),
    title: z.string().nullable(),
    anchor_link: z.string().nullable(),
    cards: z
      .object({
        id: z.string().uuid(),
        article_count: z.number(),
        audio_count: z.number(),
        display_date: z.string().nullable(),
        image_count: z.number(),
        video_count: z.number(),
        document_count: z.number(),
        technical_data_count: z.number().nullable(),
        type: z.string(),
        title: z.string(),
        location: z.string(),
        media: z.any(),
        presskit: PressKitSchema,
      })
      .array(),
  })
  .transform((data) => ({
    id: data.id,
    type: 'presskit',
    lightBg: true,
    title: data.cards[0].title ?? '',
    anchor: data.anchor_link ?? '',
    image: data.cards[0].media,
    pressKit: data.cards[0].presskit,
    metaItems: [
      {
        label: data.cards[0].article_count === 1 ? 'ARTICLE' : 'ARTICLES_N',
        count: data.cards[0].article_count,
        data: 'article_article_section',
      },
      {
        label: data.cards[0].image_count === 1 ? 'IMAGE' : 'IMAGES_N',
        count: data.cards[0].image_count,
        data: 'article_image_section',
      },
      {
        label: data.cards[0].video_count === 1 ? 'VIDEO' : 'VIDEOS_N',
        count: data.cards[0].video_count,
        data: 'article_video_section',
      },
      {
        label: data.cards[0].audio_count === 1 ? 'AUDIO' : 'AUDIOS_N',
        count: data.cards[0].audio_count,
        data: 'article_audio_section',
      },
      {
        label: data.cards[0].document_count === 1 ? 'DOCUMENT' : 'DOCUMENTS_N',
        count: data.cards[0].document_count,
        data: 'article_document_section',
      },
      {
        label: data.cards[0].technical_data_count === 1 ? 'TECHNICAL_DATA_N' : 'TECHNICAL_DATAS_N',
        count: data.cards[0].technical_data_count,
        data: 'article_technical_data_section',
      },
    ],
  }));

export type PressKit = z.infer<typeof PressKitSchema>;
export type PressKitSection = z.infer<typeof PressKitSectionSchema>;

@Injectable({
  providedIn: 'root',
})
export class PressKitAdapter implements Adapter<PressKit> {
  constructor(
    private mediaAdapter: MediaAdapter,
    private contactPersonAdapter: ContactPersonAdapter,
    private articleAdapter: ArticleAdapter
  ) {}

  public parse(data: any): PressKit {
    const result = PressKitSchema.safeParse(data);

    const context = { id: data.id, type: 'presskits' };

    if (!result.success) {
      console.error('Error parsing PressKit page data', result.error);
      throw result.error;
    } else {
      if (result.data.mainImage) {
        result.data.mainImage = new MediaImageModel(
          this.mediaAdapter.parse(result.data.mainImage, ArticleHeaderImageStyle) as MediaImageInterface
        );
      }
      if (result.data.imageCount) {
        result.data.images = result.data.images?.map(
          (image) => new MediaImageModel(this.mediaAdapter.parse(image, [], context) as MediaImageInterface)
        );
      }
      if (result.data.videoCount) {
        result.data.videos = result.data.videos?.map(
          (video) => new MediaVideoModel(this.mediaAdapter.parse(video, [], context) as MediaVideoInterface)
        );
      }
      if (result.data.documentCount) {
        result.data.documents = result.data.documents?.map(
          (document) => new MediaDocumentModel(this.mediaAdapter.parse(document, [], context) as MediaDocumentInterface)
        );
      }
      if (result.data.technicalDataCount) {
        result.data.technicalDatas = result.data.technicalDatas?.map(
          (document) =>
            new MediaTechnicalDataModel(this.mediaAdapter.parse(document, [], context) as MediaTechnicalDataInterface)
        );
      }
      if (result.data.audioCount) {
        result.data.audios = result.data.audios?.map(
          (audio) => new MediaAudioModel(this.mediaAdapter.parse(audio, [], context) as MediaAudioInterface)
        );
      }
      if (result.data.contactPersons?.length) {
        result.data.contactPersons = result.data.contactPersons.map(
          (contactPerson) => new ContactPersonModel(this.contactPersonAdapter.parse(contactPerson))
        );
      }
      if (result.data.articles?.length) {
        result.data.articles = result.data.articles.map(
          (article) => new ArticleModel({ ...this.articleAdapter.parse(article), pressKitId: result.data.id })
        );
      }

      return result.data;
    }
  }

  public parseSection(data: any) {
    const result = PressKitSectionSchema.safeParse(data);
    if (!result.success) {
      console.error('Error parsing Press kit section data', result.error.errors);
      throw result.error;
    } else {
      if (result.data.image) {
        result.data.image = new MediaImageModel(
          this.mediaAdapter.parse(result.data.image, PressKitSectionImageStyle) as MediaImageInterface
        );
      }

      return result.data;
    }
  }
}
