import { Injectable } from '@angular/core';
import { MatDialog, MatDialogState } from '@angular/material/dialog';
import { ContentAPIService } from '@modules/content-api/content-api.service';
import { catchError, EMPTY, interval, Subject, take, takeUntil } from 'rxjs';
import { z } from 'zod';

import { TrackingItem } from '../gtm/data-layer.types';
import { DownloadModalComponent } from '../modal/download-modal/download-modal.component';
import { DownloadPrepareModalComponent } from '../modal/download-prepare-modal/download-prepare-modal.component';
import { ErrorModalComponent } from '../modal/error-modal/error-modal.component';
import { SharedDownloadModalComponent } from '../modal/shared-download-modal/shared-download-modal.component';

import { DataLayerService, TrackableContent } from './data-layer.service';
import { DialogService } from './dialog.service';

export interface DownloadData {
  id: string;
  type: string;
  format: string;
  fileType: string;
  title: string;
  assets?: {
    imageCount: number;
    videoCount: number;
    documentCount: number;
    audioCount: number;
    technicalDataCount: number;
  };
  // capture download extra wurst
  serverName?: string;
  cameraName?: string;
  imageSize?: string;
  videoName?: string;
  timeCode?: string;
  streamId?: string;
}

export interface DownloadContentInterface {
  key: string;
  data?: any;
}

export interface DownloadMetaDataInterface {
  format?: string;
  size?: string; // formatted string
  contents?: DownloadContentInterface[];
}

export interface DownloadableContent {
  downloadData?: DownloadData;
}

interface DownloadModalData {
  title: string;
  type: string;
  metaData: DownloadMetaDataInterface;
  downloadUrl: string;
  downloadCb?: () => void;
}

const MAP_API_TO_DATALAYER_TYPE: {
  [k: string]:
    | 'image'
    | 'video'
    | 'audio'
    | 'document'
    | 'press_information'
    | 'press_kit'
    | 'media_collection'
    | 'technical_data';
} = {
  image: 'image',
  structured_image: 'image',
  audio: 'audio',
  video_file: 'video',
  document: 'document',
  mb_article: 'press_information',
  mb_presskit: 'press_kit',
  mb_media_collection: 'media_collection',
  technical_data: 'technical_data',
};

const DownloadResultRawSchema = z
  .object({
    data: z.object({
      attributes: z.object({
        file_size: z.coerce.number().optional(),
        file_size_formatted: z.string().optional(),
        processed: z.boolean(),
        uri: z.string().nullable(),
      }),
      downloaded_entities: z
        .object({
          id: z.string().uuid(),
          type: z.string(),
          title: z.string(),
          mars_origin_id: z.string().optional(),
          mars_shelf_number: z.string().optional(),
        })
        .array()
        .optional(),
      id: z.string().uuid(),
    }),
  })
  .transform((data) => ({
    id: data.data.id,
    fileSize: data.data.attributes.file_size,
    formattedFileSize: data.data.attributes.file_size_formatted,
    processed: data.data.attributes.processed,
    url: data.data.attributes.uri,
    downloadedEntities: data.data.downloaded_entities?.map((item) => ({
      id: item.id,
      type: MAP_API_TO_DATALAYER_TYPE[item.type],
      title: item.title,
      marsOriginId: item.mars_origin_id,
      shelfNumber: item.mars_shelf_number,
    })),
  }));

const DownloadResultSchema = z
  .object({
    id: z.string().uuid(),
    file_size: z.coerce.number().optional(),
    file_size_formatted: z.string().optional(),
    processed: z.boolean(),
    uri: z.string().nullable(),
  })
  .transform((data) => ({
    id: data.id,
    fileSize: data.file_size,
    formattedFileSize: data.file_size_formatted,
    processed: data.processed,
    url: data.uri,
  }));

@Injectable()
export class DownloadService {
  constructor(
    public dialog: MatDialog,
    private apiService: ContentAPIService,
    private dialogService: DialogService,
    private dataLayerService: DataLayerService
  ) {}

  /**
   * This function opens the download modal and prepares a downloadable ZIP archive for downloads containing more than one file.
   *
   * @param data the entity or entities the user wants to download
   * @param isShareDownload if true, will show the share-download modal (sharing of favorites, creates a shareable download link)
   * @param pushToDataLayer if false, a download will not push a download event to the GTM data-layer (use for pre-media)
   * @param downloadCb a callback function that will be executed after the user clicked the final download button
   */
  public download({
    data,
    isShareDownload = false,
    pushToDataLayer = true,
    downloadCb,
  }: {
    data: (DownloadableContent & TrackableContent) | (DownloadableContent & TrackableContent)[];
    isShareDownload: boolean;
    pushToDataLayer: boolean;
    downloadCb?: () => void;
  }) {
    if (Array.isArray(data)) {
      const parsedData = data
        .filter((item) => !!item.downloadData)
        .map((item) => ({
          ...item.downloadData,
          trackingData: item.trackingData,
        })) as (DownloadData & TrackableContent)[];
      this._download(parsedData, 'ZIP', this.getDataContents(parsedData), isShareDownload, pushToDataLayer, downloadCb);
    } else {
      if (data.downloadData) {
        const parsedData = {
          ...data.downloadData,
          trackingData: data.trackingData,
        };
        this._download(
          [parsedData],
          parsedData.format,
          this.getDataContents([parsedData]),
          isShareDownload,
          pushToDataLayer,
          downloadCb
        );
      }
    }
  }

  private _download(
    data: (DownloadData & TrackableContent)[],
    format: string,
    contents: DownloadContentInterface[] = [],
    isShareDownload: boolean,
    pushToDataLayer: boolean,
    downloadCb?: () => void
  ) {
    let title = '';
    let type = 'ZIP';
    const metaData: DownloadMetaDataInterface = { format, contents };

    if (data.length == 1) {
      title = data[0].title;
      type = data[0].fileType;
    }

    let abortDownload = new Subject<boolean>();
    let prepareModal: any = this.dialogService.open({
      component: DownloadPrepareModalComponent,
      inputs: {
        text: isShareDownload ? 'DOWNLOAD_SHARE_LOADING_PREPARING' : 'DOWNLOAD_LOADING_PREPARING',
      },
      outputs: {
        cancel: () => {
          prepareModal = null;
          abortDownload.next(true);
        },
      },
    });

    // handle user clicks outside to close dialoge
    prepareModal
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        abortDownload.next(true);
      });

    this.apiService
      .post('bulk-download', {
        data: {
          type: 'bulk-download',
          attributes: {
            files: data.map((_data) => {
              const { id, type } = _data;
              if (_data.type === 'capture') {
                return {
                  type: 'capture',
                  server_name: _data.serverName,
                  image_size: _data.imageSize,
                  camera_name: _data.cameraName,
                  video_name: _data.videoName,
                  time_code: _data.timeCode,
                  stream_id: _data.streamId,
                };
              }
              return { id, type };
            }),
          },
        },
      })
      .pipe(
        takeUntil(abortDownload),
        catchError(() => {
          this.dialogService.openNext({
            component: ErrorModalComponent,
            inputs: {
              headline: isShareDownload ? 'DOWNLOAD_SHARE_ERROR' : 'DOWNLOAD_ERROR',
            },
          });
          return EMPTY;
        })
      )
      .subscribe((res: any) => {
        const result = DownloadResultRawSchema.safeParse(res);
        if (!result.success) {
          console.error(result.error);
          return;
        }

        const download = result.data;
        metaData.size = download.formattedFileSize;

        if (download.processed) {
          this.handleDowloadReady(
            download.url!,
            metaData,
            title,
            type,
            prepareModal,
            isShareDownload,
            this.downloadCbWithTracking({
              data,
              format,
              fileSize: download.fileSize,
              resolvedContent: download.downloadedEntities,
              pushToDataLayer,
              downloadCb,
            })
          );
          return;
        }

        /**
         * 31457280 == 1024*1024*30 (30 mb)
         * add 1 second to polling time for each 30mb
         *
         * Poll between 2 and 20 seconds only
         */
        let pollingTime = download.fileSize ? (download.fileSize / 31457280) * 1000 : 10000;
        if (pollingTime < 1000) {
          pollingTime = 2000;
        } else if (pollingTime > 10000) {
          pollingTime = 10000;
        }

        interval(pollingTime)
          .pipe(takeUntil(abortDownload))
          .subscribe(() => {
            this.apiService
              .getResource('bulk-download', download.id)
              .pipe(
                takeUntil(abortDownload),
                catchError(() => {
                  this.dialogService.openNext({
                    component: ErrorModalComponent,
                    inputs: {
                      headline: isShareDownload ? 'DOWNLOAD_SHARE_ERROR' : 'DOWNLOAD_ERROR',
                    },
                  });
                  return EMPTY;
                })
              )
              .subscribe((_data: any) => {
                const result = DownloadResultSchema.safeParse(_data);
                if (!result.success) {
                  console.error(result.error);
                  return;
                }
                if (result.data.processed) {
                  this.handleDowloadReady(
                    download.url!,
                    metaData,
                    title,
                    type,
                    prepareModal,
                    isShareDownload,
                    this.downloadCbWithTracking({
                      data,
                      format,
                      fileSize: download.fileSize,
                      resolvedContent: download.downloadedEntities,
                      pushToDataLayer,
                      downloadCb,
                    })
                  );
                  abortDownload.next(true);
                }
              });
          });
      });
  }

  private handleDowloadReady(
    downloadUrl: string,
    metaData: DownloadMetaDataInterface,
    title: string,
    type: string,
    prepareModal: any,
    isShareDownload: boolean,
    downloadCb?: () => void
  ) {
    if (
      prepareModal &&
      prepareModal.getState() !== MatDialogState.CLOSING &&
      prepareModal.getState() !== MatDialogState.CLOSED
    ) {
      prepareModal
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          if (isShareDownload) {
            this.openShareDownloadModal(downloadUrl);
            return;
          }
          this.openDownloadModal({
            title,
            type,
            metaData,
            downloadUrl,
            downloadCb,
          });
        });
      prepareModal.close();
    } else {
      if (isShareDownload) {
        this.openShareDownloadModal(downloadUrl);
        return;
      }
      this.openDownloadModal({
        title,
        type,
        metaData,
        downloadUrl,
        downloadCb,
      });
    }
  }

  private getDataContents(data: DownloadData[]) {
    const fileTypesCount: any = {
      article: 0,
      image: 0,
      video: 0,
      audio: 0,
      document: 0,
      technicalData: 0,
    };
    const contents: DownloadContentInterface[] = [];
    data.forEach((item) => {
      if (item.assets) {
        fileTypesCount.image = fileTypesCount.image + item.assets.imageCount;
        fileTypesCount.video = fileTypesCount.video + item.assets.videoCount;
        fileTypesCount.document = fileTypesCount.document + item.assets.documentCount;
        fileTypesCount.technicalData = fileTypesCount.technicalData + item.assets.technicalDataCount;
        fileTypesCount.audio = fileTypesCount.audio + item.assets.audioCount;
      }
      fileTypesCount[item.type]++;
    });

    if (fileTypesCount.image) {
      contents.push({
        key: fileTypesCount.image > 1 ? 'IMAGES_N' : 'IMAGE_N',
        data: {
          count: fileTypesCount.image,
        },
      });
    }
    if (fileTypesCount.video) {
      contents.push({
        key: fileTypesCount.video > 1 ? 'VIDEOS_N' : 'VIDEO_N',
        data: {
          count: fileTypesCount.video,
        },
      });
    }
    if (fileTypesCount.document) {
      contents.push({
        key: fileTypesCount.document > 1 ? 'DOCUMENTS_N' : 'DOCUMENT_N',
        data: {
          count: fileTypesCount.document,
        },
      });
    }
    if (fileTypesCount.audio) {
      contents.push({
        key: fileTypesCount.audio > 1 ? 'AUDIOS_N' : 'AUDIO_N',
        data: {
          count: fileTypesCount.audio,
        },
      });
    }
    if (fileTypesCount.technicalData) {
      contents.push({
        key: fileTypesCount.technicalData > 1 ? 'TECHNICAL_DATAS_N' : 'TECHNICAL_DATA_N',
        data: {
          count: fileTypesCount.technicalData,
        },
      });
    }
    return contents;
  }

  private openDownloadModal(data: DownloadModalData) {
    this.dialogService.open({
      component: DownloadModalComponent,
      disableCloseBtn: true,
      inputs: data,
      outputs: {
        downloaded: () => {
          if (data.downloadCb) {
            data.downloadCb();
          }
        },
      },
    });
  }

  private openShareDownloadModal(url: string) {
    this.dialogService.open({
      component: SharedDownloadModalComponent,
      disableCloseBtn: true,
      inputs: { url },
    });
  }

  private downloadCbWithTracking({
    data,
    format,
    fileSize,
    resolvedContent,
    pushToDataLayer,
    downloadCb,
  }: {
    data: (DownloadData & TrackableContent)[];
    format: string;
    fileSize?: number;
    resolvedContent?: any[];
    pushToDataLayer: boolean;
    downloadCb?: () => void;
  }) {
    // inject tracking call into download callback
    return () => {
      if (pushToDataLayer) {
        if (format !== 'ZIP') {
          if (data[0].trackingData) {
            // single file download
            this.dataLayerService.pushEvent({
              event: 'singleDownload',
              item: data[0].trackingData,
              fileSize: fileSize,
            });
          }
        } else {
          // manually re-add screenshots
          const content = data
            .filter((item) => item.type === 'capture' && !!item.trackingData)
            .map((item) => item.trackingData as TrackingItem)
            .concat(resolvedContent ?? []);

          // multi file download
          this.dataLayerService.pushEvent({
            event: 'multiDownload',
            fileSize: fileSize,
            content,
          });
        }
      }
      if (downloadCb) {
        downloadCb();
      }
    };
  }
}
