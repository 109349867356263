<div class="small-logo-container">
  <a
    routerLink="/"
    [state]="{ scrollRestoration: 'top' }"
    (click)="closeMenu()"
    [attr.aria-label]="'GO_TO_HOMEPAGE' | translate"
  >
    <img class="small-logo" src="assets/img/mb_logo_star.svg" width="38" height="38" alt="Mercedes-Benz Media" />
  </a>
</div>
<div class="big-logo-container">
  <a
    routerLink="/"
    [state]="{ scrollRestoration: 'top' }"
    (click)="closeMenu()"
    [attr.aria-label]="'GO_TO_HOMEPAGE' | translate"
    class="big-logo-link"
    ><img class="mr-4" src="assets/img/mb_logo_star.svg" width="38" height="38" alt="Mercedes-Benz Media" />
    <img src="assets/img/mb_media_wortmarke.svg" width="138" height="11" alt="Mercedes-Benz Media" />
  </a>
</div>
<ng-container *ngIf="isSearchInputFieldClosed; else searchInputField">
  <div class="desktop-view">
    <div class="desktop-meta">
      <wb-header-meta-link href="presscontacts" (click)="openPressContacts($event)">
        {{ 'PRESS_CONTACTS' | translate }}
      </wb-header-meta-link>
      <wb-header-meta-link href="/legal/provider" (click)="openImprint($event)">
        {{ 'PROVIDER_PRIVACY' | translate }}
      </wb-header-meta-link>
      <wb-header-language-menu aria-label="{{ 'LANGUAGE_MENU' | translate }}">
        <ng-container *ngTemplateOutlet="languageList"> </ng-container>
      </wb-header-language-menu>
      <ng-container *ngIf="user$ | async as user; else notLoggedIn">
        <wb-header-button label="{{ 'OPEN_PROFILE' | translate }}" (click)="openProfileModal()">
          <div class="icon-text-button">
            <wb-icon name="bds/user/16"></wb-icon>
            <wb-text size="s">{{ user.username }}</wb-text>
          </div>
        </wb-header-button>
      </ng-container>
      <ng-template #notLoggedIn>
        <wb-header-button label="{{ 'LOGIN' | translate }}" (click)="login()">
          <div class="icon-text-button">
            <wb-icon name="bds/login/16"></wb-icon>
            <wb-text size="s">{{ 'LOGIN' | translate }}</wb-text>
          </div>
        </wb-header-button>
      </ng-template>
      <wb-header-button label="{{ 'OPEN_SEARCH' | translate }}" (click)="toggleSearchInputField(false)">
        <wb-icon name="bds/search/16"></wb-icon>
      </wb-header-button>
    </div>
  </div>

  <div class="tablet-view">
    <!-- Tablet -->
    <div class="flex-row-center">
      <wb-header-button label="{{ 'OPEN_MENU' | translate }}" (click)="toggleNavigationMenu()">
        <wb-icon name="bds/menu/16"></wb-icon>
      </wb-header-button>
      <wb-header-language-menu aria-label="{{ 'LANGUAGE_MENU' | translate }}">
        <ng-container *ngTemplateOutlet="languageList"> </ng-container>
      </wb-header-language-menu>
    </div>
    <div class="flex-row-center">
      <ng-container *ngIf="user$ | async as user; else notLoggedInTablet">
        <wb-header-button label="{{ 'OPEN_PROFILE' | translate }}" (click)="openProfileModal()">
          <div class="icon-text-button">
            <wb-icon name="bds/user/16"></wb-icon>
            <wb-text size="s">{{ user.username }}</wb-text>
          </div>
        </wb-header-button>
      </ng-container>
      <ng-template #notLoggedInTablet>
        <wb-header-button label="{{ 'OPEN_PROFILE' | translate }}" (click)="login()">
          <div class="icon-text-button">
            <wb-icon name="bds/login/16"></wb-icon>
            <wb-text size="s">{{ 'LOGIN' | translate }}</wb-text>
          </div>
        </wb-header-button>
      </ng-template>
      <wb-header-button label="{{ 'OPEN_SEARCH' | translate }}" (click)="toggleSearchInputField(false)">
        <wb-icon name="bds/search/16"></wb-icon>
      </wb-header-button>
    </div>
  </div>

  <div class="mobile-view">
    <!-- Mobile -->
    <div class="flex-row-center">
      <wb-header-button label="{{ 'OPEN_MENU' | translate }}" (click)="toggleNavigationMenu()">
        <wb-icon name="bds/menu/16"></wb-icon>
      </wb-header-button>
      <wb-header-button label="{{ 'OPEN_MY_CONTENT' | translate }}" (click)="openMyContent()">
        <wb-icon name="bds/bookmark/16"></wb-icon>
        <wb-counter
          *ngIf="notificationCount"
          class="fav-counter"
          [value]="notificationCount"
          variant="primary"
        ></wb-counter>
      </wb-header-button>
    </div>
    <div class="flex-row-center">
      <ng-container *ngIf="user$ | async as user; else notLoggedInMobile">
        <wb-header-button label="{{ 'OPEN_PROFILE' | translate }}" (click)="openProfileModal()">
          <wb-icon name="bds/user/16"></wb-icon>
        </wb-header-button>
      </ng-container>
      <ng-template #notLoggedInMobile>
        <wb-header-button label="{{ 'OPEN_PROFILE' | translate }}" (click)="login()">
          <wb-icon name="bds/login/16"></wb-icon>
        </wb-header-button>
      </ng-template>
      <wb-header-button label="{{ 'OPEN_SEARCH' | translate }}" (click)="toggleSearchInputField(false)">
        <wb-icon name="bds/search/16"></wb-icon>
      </wb-header-button>
    </div>
  </div>
</ng-container>
<ng-template #searchInputField>
  <mb-header-search-input-field
    [@inOutAnimation]
    (closeSearchInputField)="toggleSearchInputField(true)"
  ></mb-header-search-input-field>
</ng-template>

<ng-template #languageList>
  <wb-header-language-menu-item
    *ngFor="let lang of languages"
    (click)="changeLanguage(lang.isoLang)"
    [active]="currentLanguage === lang.isoLang"
  >
    {{ lang.label }}
  </wb-header-language-menu-item>
</ng-template>
