import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { DownloadMetaDataInterface } from '@modules/shared/services/download.service';
import { WindowService } from '@modules/shared/services/window.service';

export const DOWNLOAD_ICON_TYPES: any = {
  image: 'mb-icon-image-gallery',
  audio: 'mb-icon-audio',
  document: 'mb-icon-documents',
  technicalData: 'mb-icon-documents',
  video: 'mb-icon-video-movie-thin',
  article: 'mb-icon-article',
  presskit: 'mb-icon-article',
  zip: 'mb-icon-zip',
  media_collection: 'mb-icon-article',
};
export const DOWNLOAD_CONTENT_TYPES: any = {
  article: 'PRESS_RELEASE',
  media_collection: 'MEDIA_COLLECTION',
  presskit: 'PRESS_KIT',
  image: 'IMAGE',
  video: 'VIDEO',
  audio: 'AUDIO',
  document: 'DOCUMENT',
  technical_data: 'TECHNICAL_DATA',
};
@Component({
  selector: 'mb-download-modal',
  templateUrl: './download-modal.component.html',
  styleUrls: ['./download-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadModalComponent implements OnInit {
  public downloadIconType = DOWNLOAD_ICON_TYPES;
  public downloadContentType = DOWNLOAD_CONTENT_TYPES;
  public agreed = false;

  public isFirefox = navigator?.userAgent?.indexOf('Firefox') !== -1;
  public isMobile = false;

  @Input() type = '';
  @Input() title = '';
  @Input() downloadUrl = '';
  @Input() metaData: DownloadMetaDataInterface = {};

  @Output() downloaded = new EventEmitter();

  constructor(private windowService: WindowService) {}

  ngOnInit() {
    this.isMobile = this.windowService.isMobileBrowser;
  }
}
