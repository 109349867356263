import { Injectable } from '@angular/core';
import { ArticleModel } from '@modules/article/models/article.model';
import { AssetType } from '@modules/cards/types/asset.types';
import { MediaAdapter } from '@modules/content-api/adapter/media.adapter';
import { PressKit, PressKitAdapter } from '@modules/content-api/adapter/press-kit.adapter';
import { MediaAudioInterface, MediaAudioModel } from '@modules/media/models/media-audio.model';
import { MediaDocumentInterface, MediaDocumentModel } from '@modules/media/models/media-document.model';
import { MediaImageInterface, MediaImageModel } from '@modules/media/models/media-image.model';
import { MediaTechnicalDataInterface, MediaTechnicalDataModel } from '@modules/media/models/media-technical-data-model';
import { MediaVideoInterface, MediaVideoModel } from '@modules/media/models/media-video.model';
import { FileSizePipe } from '@modules/pipes/file-size.pipe';
import { LocalizedDatePipe } from '@modules/pipes/localized-date.pipe';
import { MetaItemInterface } from '@modules/ui/meta-items/meta-items.component';
import { environment } from 'src/environments/environment';
import { filetypeFromMime } from 'src/helpers/strings';
import { z } from 'zod';

import { Adapter } from '../../modules/content-api/adapter/adapter';
import { ArticleAdapter } from '../../modules/content-api/adapter/article.adapter';

const PreMediaSchema = z.object({
  id: z.string(),
  type: z.string(),
  title: z.string(),
  calendar_description: z.string().optional(),
  calendar_location: z.string().optional(),
  calender_title: z.string().optional(),
  embargo_date: z.object({ value: z.string(), timezone: z.string() }).nullable(),
  formatted_datetimes: z.array(z.string()),
  enable_calendar: z.boolean(),
  ics_link: z.string(),
  visible_timezones: z.array(z.string()),
  reference: z
    .object({
      id: z.string(),
      type: z.string(),
      pre_media_article: z.any(), // TODO: refer to proper schemas
      pre_media_presskit: z.any(),
      pre_media_media_collection: z.any(),
      pre_media_image: z.any(),
      pre_media_video: z.any(),
      pre_media_document: z.any(),
      pre_media_technical_data: z.any(),
      pre_media_audio: z.any(),
    })
    .nullable(),
});

export type PreMedia = z.infer<typeof PreMediaSchema> & {
  cardData: {
    // TODO: MediaCardDataType
    headline: string;
    media?: MediaImageModel | MediaVideoModel;
    assetType: AssetType;
    topline?: string;
    toplineIcon?: string;
    text?: string;
    fuelLabel?: string;
    metaItems?: any[];
    data?: any;
    videoDuration?: number;
    assetCount?: number;
  };
  entity?:
    | ArticleModel
    | PressKit
    | MediaImageModel
    | MediaVideoModel
    | MediaDocumentModel
    | MediaAudioModel
    | MediaTechnicalDataModel;
};

@Injectable({
  providedIn: 'root',
})
export class PreMediaAdapter implements Adapter<PreMedia> {
  constructor(
    private articleAdapter: ArticleAdapter,
    private pressKitAdapter: PressKitAdapter,
    private mediaAdapter: MediaAdapter,
    private localizedDatePipe: LocalizedDatePipe
  ) {}

  /**
   * Parses the pre-media API response into usable data
   * @param data
   */
  public parse(data: any): PreMedia {
    const parsed = PreMediaSchema.safeParse(data);

    if (parsed.success) {
      const preMedia = parsed.data;
      preMedia.ics_link = environment.apiBaseUrl + data.ics_link;

      if (preMedia.reference) {
        switch (preMedia.reference.type) {
          case 'article_pre_media': {
            const entity = preMedia.reference.pre_media_article
              ? new ArticleModel(this.articleAdapter.parse(preMedia.reference.pre_media_article))
              : undefined;

            const cardData = {
              assetType: 'PRESS_INFORMATION' as AssetType,
              headline: preMedia.title,
              media: entity?.headerImage || undefined,
              topline: [
                entity?.location,
                entity?.displayDate ? this.localizedDatePipe.transform(entity.displayDate, 'longDate') : false,
              ]
                .filter(Boolean)
                .join(', '),
              toplineIcon: entity?.location ? 'bds/location/24' : 'bds/calendar/24',
              fuelLabel: entity?.fuelLabel,
              metaItems: entity?.metaItems.filter((item) => !!item.count),
            };

            return {
              ...preMedia,
              cardData,
              entity,
            };
          }
          case 'presskit_pre_media': {
            const entity = preMedia.reference.pre_media_presskit
              ? this.pressKitAdapter.parse(preMedia.reference.pre_media_presskit)
              : undefined;

            const cardData = {
              assetType: 'PRESS_KIT' as AssetType,
              headline: preMedia.title,
              media: entity?.mainImage || undefined,
              topline: [
                entity?.location,
                entity?.displayDate ? this.localizedDatePipe.transform(entity.displayDate, 'longDate') : false,
              ]
                .filter(Boolean)
                .join(', '),
              toplineIcon: entity?.location ? 'bds/location/24' : 'bds/calendar/24',
              fuelLabel: entity?.fuelLabel,
              metaItems: entity?.metaItems.filter((item) => !!item.count),
            };

            return {
              ...preMedia,
              cardData,
              entity,
            };
          }
          case 'media_collection_pre_media': {
            const entity = preMedia.reference.pre_media_media_collection
              ? new ArticleModel(this.articleAdapter.parse(preMedia.reference.pre_media_media_collection))
              : undefined;

            if (entity) {
              entity.downloadData.type = 'media_collection';
              entity.downloadData.fileType = 'media_collection';
            }

            const cardData = {
              assetType: 'MEDIA_COLLECTION' as AssetType,
              headline: preMedia.title,
              topline: [
                entity?.location,
                entity?.displayDate ? this.localizedDatePipe.transform(entity.displayDate, 'longDate') : false,
              ]
                .filter(Boolean)
                .join(', '),
              toplineIcon: entity?.location ? 'bds/location/24' : 'bds/calendar/24',
              fuelLabel: entity?.fuelLabel,
              metaItems: entity?.metaItems.filter((item) => !!item.count),
            };

            return {
              ...preMedia,
              cardData,
              entity,
            };
          }
          case 'image_pre_media': {
            const entity = preMedia.reference.pre_media_image
              ? new MediaImageModel(this.mediaAdapter.parse(preMedia.reference.pre_media_image) as MediaImageInterface)
              : undefined;

            const cardData = {
              assetType: 'IMAGE' as AssetType,
              headline: preMedia.title,
              media: entity,
              metaItems: this.metaItemsfromMedia(entity),
              fuelLabel: entity?.fuelLabel,
            };

            return {
              ...preMedia,
              cardData,
              entity,
            };
          }
          case 'video_pre_media': {
            const entity = preMedia.reference.pre_media_video
              ? new MediaVideoModel(this.mediaAdapter.parse(preMedia.reference.pre_media_video) as MediaVideoInterface)
              : undefined;

            const cardData = {
              assetType: 'VIDEO' as AssetType,
              headline: preMedia.title,
              media: entity,
              metaItems: this.metaItemsfromMedia(entity),
              fuelLabel: entity?.fuelLabel,
            };

            return {
              ...preMedia,
              cardData,
              entity,
            };
          }
          case 'document_pre_media': {
            const entity = preMedia.reference.pre_media_document
              ? new MediaDocumentModel(
                  this.mediaAdapter.parse(preMedia.reference.pre_media_document) as MediaDocumentInterface
                )
              : undefined;

            const cardData = {
              assetType: 'DOCUMENT' as AssetType,
              headline: preMedia.title,
              metaItems: this.metaItemsfromMedia(entity),
              fuelLabel: entity?.fuelLabel,
            };

            return {
              ...preMedia,
              cardData,
              entity,
            };
          }
          case 'audio_pre_media': {
            const entity = preMedia.reference.pre_media_audio
              ? new MediaAudioModel(this.mediaAdapter.parse(preMedia.reference.pre_media_audio) as MediaAudioInterface)
              : undefined;

            const cardData = {
              assetType: 'AUDIO' as AssetType,
              headline: preMedia.title,
              metaItems: this.metaItemsfromMedia(entity),
              fuelLabel: entity?.fuelLabel,
            };

            return {
              ...preMedia,
              cardData,
              entity,
            };
          }
          case 'technical_data_pre_media': {
            const entity = preMedia.reference.pre_media_document
              ? new MediaTechnicalDataModel(
                  this.mediaAdapter.parse(preMedia.reference.pre_media_document) as MediaTechnicalDataInterface
                )
              : undefined;

            const cardData = {
              assetType: 'TECHNICAL_DATA' as AssetType,
              headline: preMedia.title,
              metaItems: this.metaItemsfromMedia(entity),
              fuelLabel: entity?.fuelLabel,
            };

            return {
              ...preMedia,
              cardData,
              entity,
            };
          }
          default: {
            throw new Error(`Unknown pre media type: ${preMedia.reference.type}`);
          }
        }
      } else {
        // missing reference
        throw new Error(`Missing reference for pre media: ${preMedia.id}`);
      }
    } else {
      // TODO: catch and handle this in the service
      throw parsed.error;
    }
  }

  private metaItemsfromMedia(
    media?: MediaImageModel | MediaVideoModel | MediaDocumentModel | MediaAudioModel | MediaTechnicalDataModel
  ): MetaItemInterface[] {
    if (!media) return [];

    const metaItems = [];
    if (media.mimeType && filetypeFromMime(media.mimeType)) {
      metaItems.push({
        label: filetypeFromMime(media.mimeType),
      });
    }
    if (media.filesize) {
      metaItems.push({
        label: new FileSizePipe().transform(media.filesize),
      });
    }
    return metaItems;
  }
}
