import { FavoriteResponseInterface } from '../interfaces/favorite-response.interface';
import { LocalFavoriteDataInterface } from '../interfaces/local-favorite-data.interface';

export class FavoriteFromApiAdapter {
  public static parse(data: FavoriteResponseInterface): LocalFavoriteDataInterface {
    const type = data.favourite_type;
    const favorite: LocalFavoriteDataInterface = {
      id: '',
      favoriteId: data.id,
      type,
      addedAt: data.created,
      updatedAt: data.changed,
    };
    const rel = data.relationships;

    if (type === 'article') {
      favorite.id = rel.article_context?.id as string;
    }
    if (type === 'presskit') {
      favorite.id = rel.presskit_context?.id as string;
    }
    if (['structured_image', 'image', 'document', 'video', 'audio', 'technical_data'].includes(type)) {
      favorite.id = rel.media?.id as string;
    }
    if (rel.article_context && type !== 'article') {
      favorite.context = rel.article_context;
    }
    /**
     * Pages and streams can not be favorised at the moment.
     * Leaving this part of code in for the case someone might have a change of mind.
     * if we want to remove this, we must only remove the type check for pages
     */
    if (rel.page_contexts && type !== 'page') {
      favorite.context = rel.page_contexts;
    }
    if (rel.stream_context && type !== 'stream') {
      favorite.context = rel.stream_context;
    }
    if (type === 'capture') {
      favorite.id = data.id;
      favorite.data = {
        originalTime: data.video_time || 1,
        time: data.current_time || 1,
        downloadUrl: data.download_url,
        activeCanvas: data.camera_id - 1,
      };
    }

    return favorite;
  }
}
