import { ContactPersonModel } from '@models/contact-person.model';
import { ContactPersonInterface } from 'src/interfaces/contact-person.interface';

import { MediaAudioInterface, MediaAudioModel } from './media-audio.model';
import { MediaDocumentInterface, MediaDocumentModel } from './media-document.model';
import { MediaImageInterface, MediaImageModel } from './media-image.model';
import { MediaTechnicalDataInterface, MediaTechnicalDataModel } from './media-technical-data-model';
import { MediaVideoInterface, MediaVideoModel } from './media-video.model';
import { MediaFactory } from './media.model';
import { TranscriptStatus } from './transcript.model';

export enum TRANSCRIPT_ENTRY_TYPE {
  AUDIO = 'AUDIO',
  DOCUMENT = 'DOCUMENT',
  IMAGE = 'IMAGE',
  LINK = 'LINK',
  TEXT = 'TEXT',
  QUOTE = 'QUOTE',
  VIDEO = 'VIDEO',
  TECHNICAL_DATA = 'TECHNICAL_DATA',
}

export interface TranscriptEntryInterface {
  id: string;
  type: string;
  chapter: string;
  text: string;
  test: boolean;
  linkText: string;
  linkUrl: string;
  quote: string;
  time: number;
  status: TranscriptStatus;
  entryType: TRANSCRIPT_ENTRY_TYPE;
  speaker: ContactPersonInterface | null;
  media: (
    | MediaImageInterface
    | MediaVideoInterface
    | MediaDocumentInterface
    | MediaAudioInterface
    | MediaTechnicalDataInterface
  )[];
}

export class TranscriptEntryModel {
  public id: string;
  public type: string;
  public chapter: string;
  public speaker!: ContactPersonModel;
  public test = false; // Used for testing the transcript before going live
  public status: TranscriptStatus = TranscriptStatus.NOT_STARTED;
  public text: string;
  public linkUrl: URL | null;
  public linkDisplayUrl: string | null;
  public linkLabel: string;
  public media: (MediaImageModel | MediaVideoModel | MediaAudioModel | MediaDocumentModel | MediaTechnicalDataModel)[] =
    [];
  public quote: string;
  public time: number;
  public timeInSeconds: number;
  public entryType: TRANSCRIPT_ENTRY_TYPE;
  public isLast = false;

  public lastItem = false; // will be set by the transcript

  constructor(d: TranscriptEntryInterface) {
    this.id = d.id;
    this.type = d.type;
    this.chapter = d.chapter || '';
    this.test = !!d.test;
    this.text = d.text || '';
    this.linkLabel = d.linkText;
    this.linkUrl = d.linkUrl ? new URL(d.linkUrl) : null;
    this.linkDisplayUrl = this.linkUrl ? this.linkUrl.hostname : null;
    this.quote = d.quote;

    this.time = d.time;
    this.timeInSeconds = d.time / 1000;
    this.status = d.status;

    this.entryType = d.entryType || TRANSCRIPT_ENTRY_TYPE.TEXT;
    if (d.speaker) {
      this.speaker = new ContactPersonModel(d.speaker);
    }
    if (d.media?.length) {
      this.media = d.media.map(
        (media: MediaImageInterface | MediaVideoInterface | MediaDocumentInterface | MediaAudioInterface) =>
          MediaFactory.create(media)
      ) as (MediaImageModel | MediaVideoModel | MediaAudioModel | MediaDocumentModel | MediaTechnicalDataModel)[];
    }
  }
}
