import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class CsrfInterceptor implements HttpInterceptor {
  /**
   * Constructor
   */
  public constructor(public httpClient: HttpClient) {}

  /*
   * HTTP Interceptor
   * Adds a CSRF token to all outgoing 'unsafe' (state changing) requests
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const config = environment;
    // GET/HEAD requests are safe and do not require a CSRF token
    const isStateless = request.method === 'GET' || request.method === 'HEAD';

    const isWhitelistedUrl = [
      // // TODO: QA form submission
      '',
    ].includes(request.url);

    // Only send CSRF token to the internal backend domains
    const isInternalDomain = [config.apiBaseUrl, config.loginUrl].some((prefix) => request.url.startsWith(prefix));
    // decide to skip CSRF token
    if (isStateless || !isInternalDomain || isWhitelistedUrl) {
      return next.handle(request);
    }
    // get CSRF token and add as extra header
    return this.httpClient
      .get(`${config.loginUrl}/csrf-token`, {
        withCredentials: true,
        responseType: 'text',
      })
      .pipe(
        switchMap((csrfToken: string) => {
          const requestWithCSRF = request.clone({ setHeaders: { [config.CSRFTokenHeader]: csrfToken } });
          return next.handle(requestWithCSRF);
        }),
        catchError(() => {
          return next.handle(request);
        })
      );
  }
}
