export const FAVORITE_FILTERS = [
  {
    label: 'ALL',
    value: 'all',
  },
  {
    label: 'ARTICLES',
    value: 'articles',
  },
  {
    label: 'PRESS_KITS',
    value: 'presskits',
  },
  {
    label: 'IMAGES',
    value: 'images',
  },
  {
    label: 'AUDIOS',
    value: 'audios',
  },
  {
    label: 'VIDEOS',
    value: 'videos',
  },
  {
    label: 'DOCUMENTS',
    value: 'documents',
  },
  {
    label: 'TECHNICAL_DATAS',
    value: 'technical_data',
  },
  // {
  //   label: 'TEXT_SNIPPETS',
  //   value: 'text_snippets'
  // },
  {
    label: 'SCREENSHOTS',
    value: 'screenshots',
  },
  {
    label: 'LINKS',
    value: 'links',
  },
];
