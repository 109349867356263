import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from '@angular/core';
import { PreMedia } from '@app/features/pre-media/pre-media.adapter';
import { Filetype } from '@modules/card/enums/filetype.enum';
import { FavoritesService } from '@modules/favorites/services/favorites.service';
import { AudioComponent } from '@modules/media/audio/audio.component';
import { DocumentPreviewComponent } from '@modules/media/document-preview/document-preview.component';
import { ImageComponent } from '@modules/media/image/image.component';
import { CaptureModel } from '@modules/media/models/capture.model';
import { MediaAudioModel } from '@modules/media/models/media-audio.model';
import { MediaDocumentModel } from '@modules/media/models/media-document.model';
import { MediaImageModel } from '@modules/media/models/media-image.model';
import { MediaTechnicalDataModel } from '@modules/media/models/media-technical-data-model';
import { MediaVideoModel } from '@modules/media/models/media-video.model';
import { MediaModelType } from '@modules/media/models/media.model';
import { MediaType } from '@modules/media/models/media.types';
import { PdfPreviewComponent } from '@modules/media/pdf-preview/pdf-preview.component';
import { VideoComponent } from '@modules/media/video/video.component';
import { BehaviorSubject, Subject, take } from 'rxjs';

import { LightboxContextLink, LightboxInterface, LightboxMetadataInterface } from '../interfaces/lightbox.interface';
import { LightboxMediaGalleryComponent } from '../lightbox/lightbox-media-gallery/lightbox-media-gallery.component';
import { LightboxComponent } from '../lightbox/lightbox.component';

import { DataLayerService } from './data-layer.service';
import { ScrollLockService } from './scroll-lock.service';
import { TrackingService, TRACKING_TYPES } from './tracking.service';

export enum LightboxState {
  OPEN = 'open',
  CLOSED = 'closed',
}

@Injectable({
  providedIn: 'root',
})
export class LightboxService {
  // private renderer: Renderer2;
  public sidebarData$ = new Subject<LightboxMetadataInterface>();
  public componentRef!: ComponentRef<LightboxComponent>;
  public activeResource?:
    | MediaImageModel
    | MediaDocumentModel
    | MediaAudioModel
    | MediaVideoModel
    | CaptureModel
    | MediaTechnicalDataModel
    | null;

  private _lightboxState = new BehaviorSubject<LightboxState>(LightboxState.CLOSED);
  public lightboxState$ = this._lightboxState.asObservable();

  public activeMediaIndex = 0;
  public mediaCount = 0;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private favoritesService: FavoritesService,
    private trackingService: TrackingService,
    private dataLayerService: DataLayerService,
    private scrollLockService: ScrollLockService,
    private injector: Injector
  ) {}

  public open(data: LightboxInterface, contextLink?: LightboxContextLink) {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    this.activeResource = data.resource;
    this.scrollLockService.lock();

    // Create a component reference from the component
    this.componentRef = this.componentFactoryResolver.resolveComponentFactory(LightboxComponent).create(this.injector);
    this.componentRef.instance.component = data.component;
    this.componentRef.instance.fullContent = !!data.fullContent;
    this.componentRef.instance.preMedia = data.preMedia;
    this.componentRef.instance.componentData = data.componentData;
    this.componentRef.instance.sidebarData = data.sidebarData || {};
    this.componentRef.instance.contextLink = contextLink;
    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(this.componentRef.hostView);

    // Get DOM element from component
    const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
    this._lightboxState.next(LightboxState.OPEN);

    this.trackingService.trackEvent(data.resource, TRACKING_TYPES.VIEWED);
    this.dataLayerService.pushEvent({
      event: 'detailView',
      item: {
        id: data.resource.id,
        type: data.resource.type as 'image' | 'video' | 'audio' | 'document' | 'capture' | 'technical_data',
        marsOriginId: data.resource instanceof CaptureModel ? undefined : (data.resource.marsOriginId ?? undefined),
        shelfNumber: data.resource instanceof CaptureModel ? undefined : (data.resource.marsShelfNumber ?? undefined),
        title: data.resource.title,
      },
    });
  }

  public openMedia(
    resource: MediaImageModel | MediaVideoModel | MediaAudioModel | MediaDocumentModel | MediaTechnicalDataModel,
    contextLink?: LightboxContextLink,
    preMedia?: PreMedia
  ) {
    let component: any = null;
    let componentData = undefined;
    let fullContent = false;

    if (resource.mediaType === MediaType.DOCUMENT || resource.mediaType === MediaType.TECHNICAL_DATA) {
      if (resource.filetypeName === Filetype.PDF) {
        component = PdfPreviewComponent;
        componentData = {
          url: resource.original.url,
          title: resource.title,
        };
        fullContent = true;
      } else {
        component = DocumentPreviewComponent;
        componentData = {
          url: resource.original.url,
          title: resource.title,
          filetypeName: resource.filetypeName,
          showOpenButton: !preMedia,
        };
        fullContent = true;
      }
    } else if (resource.mediaType === MediaType.IMAGE) {
      component = ImageComponent;
      componentData = {
        media: resource,
        isLightboxMedia: true,
      };
    } else if (resource.mediaType === MediaType.VIDEO) {
      component = VideoComponent;
      componentData = {
        media: resource,
        autoplay: true,
        allowPictureInPicture: false,
      };
    } else if (resource.mediaType === MediaType.AUDIO) {
      component = AudioComponent;
      componentData = {
        media: resource,
      };
    }

    if (component) {
      this.open(
        {
          component,
          fullContent,
          preMedia,
          componentData,
          resource,
          sidebarData: resource.getLightboxMetadata(),
        },
        contextLink
      );
    }
  }

  public openMediaGallery(
    mediaList: MediaModelType[],
    activeIndex = 0,
    contextLink: LightboxContextLink,
    preMedia?: PreMedia
  ) {
    this.mediaCount = mediaList.length;
    this.activeMediaIndex = activeIndex;
    this.open(
      {
        component: LightboxMediaGalleryComponent,
        fullContent: true,
        preMedia,
        resource: mediaList[activeIndex],
        componentData: {
          activeIndex,
          mediaList,
          isPreMedia: !!preMedia,
        },
        sidebarData: mediaList[activeIndex].getLightboxMetadata(),
      },
      contextLink
    );
  }

  public close() {
    this.componentRef?.destroy();
    this.mediaCount = 0;
    this.activeMediaIndex = 0;
    this._lightboxState.next(LightboxState.CLOSED);
    this.activeResource = null;
    this.favoritesService.favoritesOpen$.pipe(take(1)).subscribe((isOpen) => {
      if (!isOpen) {
        this.scrollLockService.unlock();
      }
    });
  }
}
