import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CardLayout } from '@modules/card/enums/card-layout.enum';
import { CaptureComponent } from '@modules/media/capture/capture.component';
import { MediaType } from '@modules/media/models/media.types';
import { BaseConfirmationComponent } from '@modules/shared/modal/base-confirmation/base-confirmation.component';
import { User } from '@modules/shared/models/user.model';
import { DialogService } from '@modules/shared/services/dialog.service';
import { DownloadService } from '@modules/shared/services/download.service';
import { LightboxService } from '@modules/shared/services/lightbox.service';
import { ScrollLockService } from '@modules/shared/services/scroll-lock.service';
import { TrackingService, TRACKING_TYPES } from '@modules/shared/services/tracking.service';
import { UserService } from '@modules/shared/services/user.service';
import { ActionIcons, ButtonItem } from '@modules/ui/action-container/action-container.component';
import { Option } from '@modules/ui/interfaces/option.interface';
import { startOfDay } from 'date-fns';
import { BehaviorSubject, combineLatest, Observable, take } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Favorite } from '../models/favorite.model';
import { FavoritesContentService } from '../services/favorites-content.service';
import { FavoritesService } from '../services/favorites.service';

import { FAVORITE_FILTERS } from './favorites.filter';

const ALIASES: { [key: string]: string[] } = {
  articles: ['article'],
  presskits: ['presskit'],
  images: ['image', 'structured_image'],
  videos: ['video'],
  audios: ['audio'],
  documents: ['document'],
  streams: ['stream'],
  screenshots: ['capture'],
  technical_data: ['technical_data'],
};

@Component({
  selector: 'mb-favorites-modal',
  templateUrl: './favorites-modal.component.html',
  styleUrls: ['./favorites-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavoritesModalComponent {
  public rowActions: ButtonItem[] = [
    {
      icon: ActionIcons.DELETE,
      label: 'DELETE',
      class: 'btn btn-secondary-dark',
      labelClass: 'd-none d-sm-inline-block',
    },
    {
      icon: ActionIcons.SHARE,
      label: 'SHARE',
      class: 'btn btn-secondary-dark',
      labelClass: 'd-none d-sm-inline-block',
    },
    { icon: ActionIcons.DOWNLOAD, label: 'DOWNLOAD', class: 'btn', labelClass: 'd-none d-sm-inline-block' },
  ];
  public openAccordions: { [key: number]: boolean } = {};
  public cardLayout = CardLayout.COLUMN;
  public mediaType = MediaType;
  public activeFilter$ = new BehaviorSubject('all');
  public favorites$: Observable<{ date: number; items: Favorite[] }[]>;
  public favoritesCount$: Observable<number>;
  public filters: Option[] = FAVORITE_FILTERS;
  public user$: Observable<User | null>;
  @Output() closed = new EventEmitter();

  constructor(
    private favoriteContentService: FavoritesContentService,
    private favoritesService: FavoritesService,
    private lightboxService: LightboxService,
    private router: Router,
    private dialogService: DialogService,
    private userService: UserService,
    private downloadService: DownloadService,
    private trackingService: TrackingService,
    private scrollLockService: ScrollLockService
  ) {
    this.user$ = this.userService.user$;
    this.favoritesCount$ = this.favoriteContentService.favorites$.pipe(map((data) => data.length));
    this.favorites$ = this.activeFilter$.pipe(
      switchMap((activeFilter) => {
        return this.favoriteContentService.favorites$.pipe(
          map((favorites) => {
            return favorites.filter(
              (favorite) => activeFilter === 'all' || (ALIASES[activeFilter] || [activeFilter]).includes(favorite.type)
            );
          }),
          map((favorites) => {
            const _favoritesListObject: { [key: string]: { date: number; items: Favorite[] } } = {};
            favorites.forEach((favorite) => {
              const key = startOfDay(favorite.date).getTime();

              if (!_favoritesListObject[key]) {
                _favoritesListObject[key] = {
                  date: key,
                  items: [],
                };
              }
              _favoritesListObject[key].items.unshift(favorite);
            });

            const favoritesList = Object.keys(_favoritesListObject)
              .sort((a, b) => parseInt(b, 10) - parseInt(a, 10))
              .map((date) => _favoritesListObject[date]);
            this.openAccordions = {};
            if (favoritesList.length) {
              this.openAccordions[favoritesList[0].date] = true;
            }

            return favoritesList;
          })
        );
      })
    );
  }

  public login() {
    this.userService.login();
  }

  setFilter(filter: string) {
    this.activeFilter$.next(filter);
  }

  rowAction(action: ActionIcons, section: { date: number; items: Favorite[] }) {
    if (action === ActionIcons.DELETE) {
      const deleteSectionFn = () => {
        section.items.forEach((item) => {
          this.favoritesService.remove(item.id, false, true);
        });
      };
      if (localStorage && localStorage.rememberDeleteFavorites) {
        deleteSectionFn();
      } else {
        const ref = this.dialogService.open({
          component: BaseConfirmationComponent,
          inputs: {
            headline: 'REMOVE_CONFIRM_CONTENT',
            confirmLabel: 'DELETE',
          },
          outputs: {
            confirm: () => {
              deleteSectionFn();
              ref.close();
            },
            cancel: () => {
              ref.close();
            },
          },
        });
      }
    } else if (action === ActionIcons.SHARE || action === ActionIcons.DOWNLOAD) {
      // TODO: return a link to download all favorites in section
      combineLatest(section.items.map((item) => item.data$))
        .pipe(take(1))
        .subscribe((items) => {
          this.downloadService.download({
            data: items.map((item) => item.data),
            isShareDownload: action === ActionIcons.SHARE,
            pushToDataLayer: true,
          });
          items.map((item) => {
            // FIXME, consolidate so tracking can be done within download service
            this.trackingService.trackEvent(
              item.data,
              action === ActionIcons.SHARE ? TRACKING_TYPES.SHARE : TRACKING_TYPES.DOWNLOAD
            );
          });
        });
    }
  }

  cardTrigger(data: any) {
    switch (data.type) {
      case 'article':
        this.router.navigate(['article', data.id]);
        this.scrollLockService.unlock();
        break;
      case 'presskit':
        this.router.navigate(['press-kit', data.id]);
        this.scrollLockService.unlock();
        break;
      case 'image':
      case 'structured_image':
      case 'video':
      case 'audio':
      case 'document':
      case 'technical_data':
        this.lightboxService.openMedia(data, data.connectedData);
        break;
      case 'capture':
        this.lightboxService.open({
          component: CaptureComponent,
          resource: data,
          componentData: {
            capture: data,
            controls: true,
          },
          sidebarData: data.getLightboxMetadata(),
        });
    }
  }
}
