import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PressKitAdapter } from '@modules/content-api/adapter/press-kit.adapter';
import { ContentAPIService } from '@modules/content-api/content-api.service';
import { catchError, map, of, shareReplay } from 'rxjs';

const PRESS_KIT_PAGE_PARAMS = new HttpParams().appendAll({
  include: [
    'articles.header_image.image',
    'articles.header_image.adjusted_image',
    'articles.header_image.alternate_images.image',
    'articles.header_image.alternate_images.adjusted_image',
    'articles.audios.audio',
    'articles.videos.video',
    'articles.videos.poster_image',
    'articles.images.image',
    'articles.images.adjusted_image',
    'articles.documents.file',
    'articles.technical_data.file',
    'articles.press',
    'contact_persons',
    'audios.audio',
    'documents.file',
    'technical_data.file',
    'images.image',
    'images.adjusted_image',
    'videos.video',
    'videos.poster_image',
    'main_image.image',
    'main_image.adjusted_image',
    'main_image.alternate_images.adjusted_image',
    'topic_tree',
  ].join(),
});

@Injectable({
  providedIn: 'root',
})
export class PressKitPageService {
  constructor(
    private apiService: ContentAPIService,
    private pressKitAdapter: PressKitAdapter
  ) {}

  public getPressKit(pressKitId: any) {
    return this.apiService.getResource('presskits', pressKitId, PRESS_KIT_PAGE_PARAMS).pipe(
      catchError(() => of(null)),
      map((data) => {
        try {
          return this.pressKitAdapter.parse(data ?? null);
        } catch (error) {
          return null;
        }
      }),
      shareReplay(1)
    );
  }
}
